import React, { useEffect } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import DashboardHeader from "../../../components/DashboardHeader";
import NavbarContainer from "../../../components/NavbarContainer";
import FooterSection from "../FooterSection";
import {
  SELECT_YOUR_NEXT_GYM,
  JACKED_RABBIT_MODE_DESCRIPTION,
  MEMBERSHIP_UPGRADED,
  ACTIVE_MEMBERSHIPS,
  NO_ACTIVE_MEMBERSHIP,
  PLEASE_COMPLETE_THE_PAYMENT_FLOW,
  OK,
} from "../../../utils/strings";
import InfoCard from "../../../components/InfoCard";
import { DefaultAvatarMember } from "../../../assets/images";
import GymCard from "../../../components/GymCard";
import { getUserMembershipStatusCall } from "../../../api/apiOperations";
import { useApi } from "../../../hooks";
import {
  filterByName,
  formatDate,
  sortGymsByCustomOrder,
} from "../../../utils/utils";
import {
  Container,
  SubContainer,
  Tile,
  GymHopContainer,
  GymHopeTitle,
  GymHopeDescription,
  GymHop,
  Box,
} from "./styles";
import { deleteUserFirebase, logoutFirebase } from "../../../auth/operations";
import EOMButton from "../../../components/EOMButton";
import theme from "../../../utils/theme";
import { useMediaQuery } from "react-responsive";
import { isTabletOrMobileSize } from "../../../utils/queries";
import { setGymOwnerLoggedIn, setLoggedIn } from "../../../slices/authSlice";

const DashboardMembers = () => {
  const {
    allBusiness,
    userMembershipStatus,
    businessProfile,
    membershipUpgraded,
    isNextGymSelected,
    userCanDowngradeToBasic,
  } = useSelector((state: RootState) => state.appData);

  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  const customOrdererArr = sortGymsByCustomOrder(
    allBusiness,
    "SWEAT440 Midtown Miami"
  );
  const { memberships, settings } = userMembershipStatus || {};

  const { name } = memberships?.active?.business || {};
  const { expiresAt } = memberships?.active || {};

  const { price } = filterByName(businessProfile?.products, "premium") || {};
  const basicProduct = filterByName(businessProfile?.products, "basic") || {};

  const { preTaxCost } = price || 0;
  const basicPrice = basicProduct?.price?.preTaxCost || 0;
  const userWithIncompletePaymentFlow = !settings?.currentProductId;

  const upgradePrice = preTaxCost - basicPrice;

  const { request: getUserMembershipStatusCallReq } = useApi<any>({
    apiFunc: getUserMembershipStatusCall,
  });
  const deleteUser = async () => {
    await deleteUserFirebase();
  };

  useEffect(() => {
    getUserMembershipStatusCallReq();
  }, []);

  return (
    <Container>
      <NavbarContainer dashboardScreen />
      {userWithIncompletePaymentFlow ? (
        <SubContainer style={{ height: "500px" }}>
          <Tile>{PLEASE_COMPLETE_THE_PAYMENT_FLOW}</Tile>
          <EOMButton
            title={OK}
            onPress={() => deleteUser()}
            buttonStyle={{
              backgroundColor: theme.templateColors.lightYellow,
              borderRadius: 3,
              width: 100,
              marginTop: 20,
            }}
            titleStyle={{
              color: theme.templateColors.secondaryGrey,
              fontSize: isTabletOrMobile ? 13 : 16,
              textAlign: "center",
              fontWeight: 500,
            }}
          />
        </SubContainer>
      ) : (
        <>
          <DashboardHeader avatar={DefaultAvatarMember} />
          {!!memberships?.settings?.scheduledCancelAt ? (
            <SubContainer>
              <Tile>{NO_ACTIVE_MEMBERSHIP}</Tile>
            </SubContainer>
          ) : (
            <>
              <SubContainer>
                <Tile>{ACTIVE_MEMBERSHIPS}</Tile>
                <InfoCard
                  tileOne={name}
                  subtile={""}
                  title={""}
                  description={"Membership expiration: "}
                  descriptionTwo={memberships?.active?.product?.name}
                  descriptionThree={formatDate(expiresAt)}
                  bottomTile={
                    membershipUpgraded
                      ? MEMBERSHIP_UPGRADED
                      : `Upgrade membership for $ ${upgradePrice.toFixed(
                          2
                        )} USD`
                  }
                  upgradeMembershipPrice={upgradePrice}
                />
              </SubContainer>
              {(!isNextGymSelected || userCanDowngradeToBasic) && (
                <GymHopContainer>
                  <GymHopeTitle>{SELECT_YOUR_NEXT_GYM}</GymHopeTitle>
                  <Box>
                    <GymHopeDescription>
                      {JACKED_RABBIT_MODE_DESCRIPTION}
                    </GymHopeDescription>
                  </Box>
                  <GymHop>
                    {customOrdererArr?.map((gymCard: any) => (
                      <GymCard key={gymCard?.id} gymCard={gymCard} />
                    ))}
                  </GymHop>
                </GymHopContainer>
              )}
            </>
          )}
        </>
      )}
      <FooterSection />
    </Container>
  );
};

export default DashboardMembers;
